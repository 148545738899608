import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import DataController from 'lib/controllers/DataController.js';
import {actions} from 'store/store.js';
import {formatErrorObj} from 'lib/functions';
import {ReactComponent as WelcomeGroupIcon} from 'images/home-group.svg';
import {ReactComponent as OhmGuyIcon} from 'images/ohm-guy.svg';
import Card from '@material-ui/core/Card';
import useAppState from 'lib/hooks/useAppState.js';
import useCallbackAuthenticated from 'lib/hooks/useCallbackAuthenticated.js';
import queryString from 'query-string';
import LoginForm from 'components/elements/LoginPage/LoginForm.js';
import ForgotPasswordForm from 'components/elements/LoginPage/ForgotPasswordForm.js';
import {Box, makeStyles} from '@material-ui/core';
import theme from 'theme';
import {useSnackbar} from 'notistack';
import useLinkHandler from '../../lib/hooks/useLinkHandler';
import {accountLoginRedirectURL} from '../../lib/config.js';
import SkeletonLoginForm from '../elements/LoginPage/SkeletonLoginForm.js';
import {wwwUrl} from 'lib/config.js';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: ({login}) => (login ? 20 : 80),
    maxWidth: '100vw',
    overflow: 'hidden',
    justifyContent: 'center',

    [theme.default.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '100px !important',
    },

    [theme.default.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      paddingTop: ({login}) => (login ? '10% !important' : `20% !important`),
    },
  },
  formContainer: {
    maxWidth: 448,
    width: 'calc(100vw - 32px)',
    zIndex: 1,

    '& h2': {
      fontSize: 21,
    },

    '& h3': {
      fontSize: 21,
    },

    '& h6': {
      fontSize: 15,
    },

    [theme.default.breakpoints.up('sm')]: {
      '& h2': {
        fontSize: 34,
      },

      '& h3': {
        fontSize: 34,
      },

      '& h6': {
        fontSize: 20,
      },
    },

    [theme.default.breakpoints.up('lg')]: {
      '& h2': {
        fontSize: 55,
      },
    },
  },
  signupbox: {
    padding: '32px 0',
    textAlign: 'center',

    '& p': {
      color: theme.default.palette.neutral[700],
      fontSize: 20,
      margin: '0 4px',
    },

    '& a': {
      fontSize: 20,
      margin: '0 4px',
    },
  },
  welcome: {
    minWidth: 900,
    order: 0,
    zIndex: 0,

    '& svg': {
      height: 'auto',
      maxWidth: '100%',
      transform: ({login}) => (login ? 'translateY(38%)' : 'translateY(-5%)'),
      width: ({login}) => (login ? 312 : 122),
    },

    [theme.default.breakpoints.up('sm')]: {
      ...({login}) =>
        login
          ? {
              display: 'block',
            }
          : {
              display: 'flex',
              marginTop: -40,
              marginBottom: 40,
            },
      maxHeight: 294,
      order: 2,
      overflow: 'hidden',
      zIndex: 2,

      '& svg': {
        /*transform: 'none',*/
        transform: ({login}) => (login ? 'translateY(0)' : 'translateY(-5%)'),
        width: ({login}) => (login ? 'auto' : '318px'),
      },
    },

    [theme.default.breakpoints.up('lg')]: {
      ...({login}) =>
        login
          ? {}
          : {
              display: 'block',
              marginTop: 0,
              marginBottom: 0,
            },
      marginRight: -18,
      marginTop: ({login}) => (login ? 130 : 0),
      maxHeight: 'unset',
      order: 0,
      overflow: 'visible',

      '& svg': {
        transform: ({login}) => (login ? 'translateY(5%)' : 'translateY(12%)'),
        width: ({login}) => (login ? 'auto' : '525px'),
      },
    },
  },
}));

export default function LoginPage() {
  const {pathname, search} = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState(true);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const {dispatch} = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const {getLinkAnchorProps} = useLinkHandler();
  const history = useHistory();
  const classes = useStyles({login});

  const resetLoginForm = () => {
    setEmail('');
    setPassword('');
    setErrors({});
  };

  const loggedInUserRedirect = () => {
    let destination = accountLoginRedirectURL;

    if (search) {
      const parseQs = queryString.parse(search);
      if (parseQs && parseQs.goto) {
        destination = parseQs.goto;
      }
    }

    if (destination.startsWith('/')) {
      history.push(destination);
    } else if (new RegExp(/^(?:http(s)?:\/\/)?(?:[a-zA-Z0-9]{0,50}\.)?addy\.co(.)*/g).test(destination)) {
      window.location = destination;
    } else {
      console.error('[loggedInUserRedirect] unable to redirect to that URL');
    }
  };

  useEffect(() => {
    const isLoginPage = pathname === '/login';

    setLogin(isLoginPage);

    return () => {
      resetLoginForm();
    };
  }, [pathname]);

  useCallbackAuthenticated(
    () => {
      // done loading, user auth'd
      // setSkeletonLoading(false);
      loggedInUserRedirect();
    },
    () => {
      setSkeletonLoading(false);
      // done loading, user not auth'd
    },
    () => {
      setSkeletonLoading(true);
    }
  );

  const handleLogin = (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    DataController.login(email, password).then(({data, errors, message, success}) => {
      if (success) {
        dispatch({
          type: actions.SET_USER_DATA,
          payload: data,
        });
        loggedInUserRedirect();
      } else {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else if (message)
          enqueueSnackbar(message, {
            variant: 'error',
          });
      }
      setIsLoading(false);
    });
  };

  const handleResetSend = (e) => {
    e.preventDefault();

    DataController.forgotPassword(email).then(({errors, message, success}) => {
      if (!success) {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar(message);
      }
    });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    history.push('/password-reset');
  };

  const handleTabClick = (e, newValue) => {
    if (newValue === 0) {
      history.push('/login');
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.welcome} display="flex" justifyContent="center">
        {login ? <WelcomeGroupIcon /> : <OhmGuyIcon />}
      </Box>

      <Box className={classes.formContainer}>
        <Card>
          {login ? (
            skeletonLoading ? (
              <SkeletonLoginForm />
            ) : (
              <LoginForm
                email={email}
                errors={errors}
                handleLogin={handleLogin}
                handleForgotPassword={handleForgotPassword}
                isLoading={isLoading}
                password={password}
                setEmail={setEmail}
                setPassword={setPassword}
              />
            )
          ) : (
            <ForgotPasswordForm
              email={email}
              errors={errors}
              handleResetSend={handleResetSend}
              handleTabClick={handleTabClick}
              isLoading={isLoading}
              setEmail={setEmail}
            />
          )}
        </Card>

        <Box
          alignItems="center"
          className={classes.signupbox}
          flexDirection="row"
          display="flex"
          justifyContent="center"
          mb="48px"
        >
          {login && (
            <>
              <Typography>New to Addy?</Typography>

              <a {...getLinkAnchorProps({url: wwwUrl + '/register', target: '_self'})}>Sign up</a>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

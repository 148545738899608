import {useEffect, useState} from 'react';
import useAppState from './useAppState.js';
import {isTokenValid, getCookies} from '../functions.js';
import DataController from 'lib/controllers/DataController.js';
import actions from 'store/actions.js';
import {dashboardCookieName} from 'lib/config.js';

const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingCookie, setIsCheckingCookie] = useState(false);
  const {state, dispatch} = useAppState();
  const token = state.token;
  const tokenCreatedAt = state.tokenCreatedAt;

  useEffect(() => {
    let didCancel = false;

    if (isTokenValid(token, tokenCreatedAt)) {
      setIsAuthenticated(true);
    } else {
      const dashCookie = getCookies(dashboardCookieName);

      if (!dashCookie) {
        setIsAuthenticated(false);
      } else {
        setIsCheckingCookie(true);
        DataController.loginViaRefresh(dashCookie).then(({success, data, error, message}) => {
          if (success) {
            if (!didCancel) {
              dispatch({
                type: actions.LOGIN,
                type: actions.SET_USER_DATA,
                payload: data,
              });
              setIsAuthenticated(true);
              setIsCheckingCookie(false);
            }
          } else {
            setIsCheckingCookie(false);
          }
        });
      }
    }

    return () => {
      didCancel = true;
    };
  }, [token, tokenCreatedAt]);

  return {isAuthenticated, isCheckingCookie};
};

export default useIsAuthenticated;
